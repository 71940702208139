import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import find from 'lodash/find';

export default {
  data() {
    return {
      loadingTopics: [],
    };
  },
  watch: {},
  computed: {
    ...mapFields('topic', {
      topicsById: 'byId',
      topicIds: 'allIds',
      topicIdsCached: "cacheAllIds",
    }),
    ...mapGetters('topic', {
      topicItems: 'list',
      findTopic: 'find'
    }),
    groupedTopics() {
      let topics = [];
      let firstLevelTopics = [];
      let childrenTopics = []

      Object.values(this.topicsById).forEach(topic => {
        if (topic.parent === null) {
          firstLevelTopics.push(topic);
        } else {
          childrenTopics.push(topic);
        }
      });
      firstLevelTopics.forEach(parentTopic => {
        if (parentTopic.children !== null) {
          topics.push(this.resolveTopicChildren(parentTopic, childrenTopics));
        }
      });
      return this.makeGroupedTopics(topics);
    },
  },
  methods: {
    ...mapActions('topic', {
      retrieveTopic: 'load',
      fetchAllTopics: 'fetchAll',
    }),
    resolveTopic(itemId) {
      if (!this.topicIdsCached.includes(itemId) && !this.loadingTopics.includes(itemId)) {
        this.loadingTopics.push(itemId);
        this.retrieveTopic(decodeURIComponent(itemId));
      }
      return this.findTopic(itemId) ?? null;
    },
    resolveTopicAsync(itemId) {
      const poll = resolve => {
        if (this.isTopicLoaded((itemId))) {
          resolve(this.resolveTopic(itemId));
        } else {
          this.resolveTopic(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    isTopicLoaded(itemId) {
      return this.topicIdsCached.includes(itemId);
    },
    resolveTopics(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return typeof itemId === 'object' ? this.resolveTopic(itemId['topic']) : this.resolveTopic(itemId)
      }).filter(e => e);
    },
    resolveTopicChildren(currentTopic, children) {
      let childTopics = [];
      currentTopic.children.forEach(childTopic => {
        let child = find(children, (ct) => ct['@id'] === childTopic);
        if (child && child.children !== null) {
          childTopics.push(this.resolveTopicChildren(child, children));
        }
      });
      return {
        current: currentTopic,
        children: childTopics
      };
    },
    makeGroupedTopics(topics, level = 0) {
      let groupedTopics = [];
      topics.forEach(topic => {
        let hasChildren = false;
        if (topic.children.length > 0) {
          hasChildren = true;
        }
        groupedTopics.push({
          element: topic.current,
          hasParent: !!(topic.current.parent),
          hasChildren: hasChildren,
          isChildren: (level > 0),
          level: level,
        });
        if (hasChildren) {
          groupedTopics = groupedTopics.concat(this.makeGroupedTopics(topic.children, (level + 1)));
        }
      });
      return groupedTopics
    },
    topicPadding(topic) {
      let padding = 10 * topic.level;
      return padding + ((padding > 0) ? 'px' : '');
    },
    getTopicItem(itemId, type) {
      let topic = typeof itemId === 'object' ? this.resolveTopic(itemId['topic']) : this.resolveTopic(itemId);
      if (topic !== null) {
        return topic[type];
      }
      return '';
    },
  }
};
